import React from 'react'
import styled from 'styled-components'
import { Card } from '@apeswapfinance/uikit'

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 992px;
  padding-left: 16px;
  padding-right: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Page = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;

  max-width : 90%;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 24px;
    padding-bottom: 24px;

  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 32px;
    padding-bottom: 32px;
      margin-right : 240px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding-top: 32px;
    padding-bottom: 32px;
      margin-right : 240px;
  }
`


export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, idName }: { children: React.ReactNode, idName?: string }) {
  return <BodyWrapper id={idName}>{children}</BodyWrapper>
}
